<template>
	<v-card 
		class="rounded-lg pa-5" 
		style="border-radius: 25px; border: 1px solid grey;"
		:loading="loading"
	>
		<template slot="progress">
			<v-progress-linear color="red" indeterminate></v-progress-linear>
		</template>
		<template
			v-if="!loading"
		>
			<v-row>
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>{{ selectionCounter.seat ? selectionCounter.seat.label : '-' }}</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(priceInfo.base_price) }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="selectionCounter.main_color.counter > 0">
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>Main Color</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(0) }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="selectionCounter.secondary_color.counter > 0">
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>Secondary Color</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(0) }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="selectionCounter.seam.counter > 0">
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>Seam</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(0) }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="selectionCounter.logo.counter > 0">
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>Logo</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(0) }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>{{ pattern_sum > 0 ? pattern_sum: ''}} Option(s) / Pattern:</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(priceInfo.pattern_price) }}</span>
					</div>
				</v-col>
			</v-row>
			<!-- <v-row v-if="selectionCounter.special_material.counter > 0">
				<v-col col="6">
					<div class="float-left pl-4">
						<span>- Special Material {{ selectionCounter.special_material.counter == 1 ? '' : ('('+selectionCounter.special_material.counter+')') }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col v-if="selectionCounter.perforation.counter > 0">
					<div class="float-left pl-4">
						<span>- Perforation {{ selectionCounter.perforation.counter == 1 ? '' : ('('+selectionCounter.perforation.counter+')') }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="selectionCounter.psp.counter > 0">
				<v-col col="6">
					<div class="float-left pl-4">
						<span>- PSP {{ selectionCounter.psp.counter == 1 ? '' : ('('+selectionCounter.psp.counter+')') }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col v-if="selectionCounter.pf.counter > 0">
					<div class="float-left pl-4">
						<span>- Pattern Stitching {{ selectionCounter.pf.counter == 1 ? '' : ('('+selectionCounter.pf.counter+')') }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="selectionCounter.pd.counter > 0">
				<v-col col="6">
					<div class="float-left pl-4">
						<span>- Digital Punching {{ selectionCounter.pd.counter == 1 ? '' : ('('+selectionCounter.pd.counter+')') }}</span>
					</div>
				</v-col>
			</v-row> -->
			<v-row v-if="priceInfo.shipping_fee && priceInfo.shipping_fee>0">
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>Shipping Fee</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(priceInfo.shipping_fee) }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="priceInfo.voucher_discount && priceInfo.voucher_discount>0">
				<v-col col="9" sm="9">
					<div class="float-left">
						<span>Voucher Discount</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(priceInfo.voucher_discount * -1) }}</span>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col col="9" sm="9">
					<div class="float-left font-weight-bold">
						<span>Total</span>
					</div>
				</v-col>
				<v-col col="3" sm="3">
					<div class="float-right">
						<span>{{ toCurrencyFormat(priceInfo.grand_total) }}</span>
					</div>
				</v-col>
			</v-row>
		</template>
	</v-card>
</template>

<script>
export default {
	components:{

	},
	mixins: [
	],
	props:{
		selectionCounter:{
			type: Object,
			default: ()=>{ return {} }
		},
		priceInfo:{
			type: Object,
			default: ()=>{ return {
				currency: {}
			} }
		},
		loading:{
			type:Boolean,
			default: ()=>{ return false }
		}
	},
	data(){
		return {
		}
	},
	computed:{
		pattern_sum()
		{
			return this.selectionCounter.special_material.counter + this.selectionCounter.perforation.counter + this.selectionCounter.psp.counter + this.selectionCounter.pf.counter + this.selectionCounter.pd.counter 
		}
	},
	created(){
	},
	methods:{
		toCurrencyFormat(value){
			let formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: this.priceInfo.currency ? this.priceInfo.currency.iso_code : 'MYR',
				minimumFractionDigits: 0
			});
			return formatter.format(value);
		}
	}
}
</script>
<style scoped>
.screenshots {
  border: 2px solid #555;
}
</style>
