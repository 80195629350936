<template>
	<div class="fill-height fill-width">
		<v-container
			fluid 
			class="pa-0" 
			style="height: calc(100vh - 60px);" 
		>
			<div
				class="px-10 pt-10 fill-height fill-width"
			>
				<v-row 
					no-gutters
				>
					<v-col cols="12" md="auto" class="title mb-1">
						<span
							@click="$router.push('/new-order')"
						>
							Vehicle Information
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span
							@click="$router.push('/new-order/select-seat')"
						>
							Select Seat Model
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{backToCustomizationPage(true)}">Customize Seat</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{$router.push('/new-order/order-options')}">Options</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{$router.push('/new-order/view-2d')}">View 2D</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{backToCustomizationPage(false)}">View 3D</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{$router.push('/new-order/order-summary')}">Order Summary</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span class="black--text">Payment & Delivery</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-row>
							<v-col cols="12" md="4">
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Estimated Time of Delivery</span>
										<p class="font-weight-black mt-3 pb-1">
											{{ orderCustomizations.estimatedTimeDelivery ? orderCustomizations.estimatedTimeDelivery.date_from + ' - ' + orderCustomizations.estimatedTimeDelivery.date_to : 'N/A' }}
										</p>
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<v-card
											outlined 
											color="transparent"
											class=" pb-4"
										>
											<span>Delivery Address</span>
											<span class="error--text"> *</span>
											<v-container class="pt-0">
												<v-radio-group
													v-model="selectedDeliveryAddressType"
													column
												>
													<v-radio
														label="Use Default"
														value="default"
														class="font-weight-black"
														:disabled="optionDeliveryAddress.length == 0"
													></v-radio>
													<div
														v-if="optionDeliveryAddress.length > 0"
														class="pl-9"
													>
														<v-select
															v-model="selectedDeliveryAddress"
															filled rounded
															style="background-color: white;"
															dense hide-details
															placeholder="Select Delivery Address"
															class="radius-10 pr-0 white-space:pre-wrap"
															:items="optionDeliveryAddress"
															item-value="id"
															return-object
														>
															<div slot="selection" slot-scope="data" style="white-space: pre-line">
																<p> {{ data.item.address }} </p>
																<p> {{ data.item.address2 }} </p>
																<p> {{ data.item.postcode + ' ' + data.item.city }} </p>
																<p> {{ data.item.state + ' ' + data.item.country }} </p>
															</div>
															<div slot="item" slot-scope="data" style="white-space: pre-line">
																<p> {{ data.item.address }} </p>
																<p> {{ data.item.address2 }} </p>
																<p> {{ data.item.postcode + ' ' + data.item.city }} </p>
																<p> {{ data.item.state + ' ' + data.item.country }} </p>
															</div>
														</v-select>
													</div>
													<div
														v-else
														class="pl-9 pb-4"
													>
														<span>N/A</span>
													</div>
													<v-radio
														label="New Address"
														value="new"
														class="font-weight-black"
													></v-radio>
													<div
														v-show="selectedDeliveryAddressType == 'new'"
														class="pl-9"
													>
														<v-text-field
															v-model="deliveryAddress.addressLine1"
															label="Address Line 1"
															dense
														>
															<template v-slot:label>
																<span>Address Line 1</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-text-field
															v-model="deliveryAddress.addressLine2"
															label="Address Line 2"
															dense
														>
														</v-text-field>
														<v-text-field
															v-model="deliveryAddress.addressCity"
															label="City"
															dense
														>
															<template v-slot:label>
																<span>City</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-text-field
															v-model="deliveryAddress.addressPostcode"
															label="Postcode"
															dense
															@blur="getCourierPayload()"
														>
															<template v-slot:label>
																<span>Postcode</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-text-field
															v-model="deliveryAddress.addressState"
															label="State"
															dense
														>
															<template v-slot:label>
																<span>State</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-autocomplete
															v-model="deliveryAddress.addressCountry"
															dense hide-details
															color="secondary"
															placeholder="Select Country"
															class="radius-10 pr-0"
															:items="optionCountryList"
															@change="getCourierPayload()"
														>
															<template v-slot:label>
																<span>Country</span>
																<span class="error--text"> *</span>
															</template>
														</v-autocomplete>
													</div>
												</v-radio-group>
											</v-container>
										</v-card>	
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row 
									v-if="isCustomer || isShowroom" 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<v-card
											outlined 
											color="transparent"
											class=""
										>
											<span>Billing Address</span>
											<span class="error--text"> *</span>
											<v-container class="pt-0 pb-1">
												<v-radio-group
													v-model="selectedBillingAddressType"
													column
												>
													<v-radio
														label="Use Default"
														value="default"
														class="font-weight-black"
														:disabled="optionBillingAddress.length == 0"
													></v-radio>
													<div
														v-if="optionBillingAddress.length > 0"
														class="pl-9"
													>
														<v-select
															v-model="selectedBillingAddress"
															filled rounded
															style="background-color: white;"
															dense hide-details
															placeholder="Select Billing Address"
															class="radius-10 pr-0"
															:items="optionBillingAddress"
															item-value="id"
															return-object
														>
															<div slot="selection" slot-scope="data">
																<p> {{ data.item.address }} </p>
																<p> {{ data.item.address2 }} </p>
																<p> {{ data.item.postcode + ' ' + data.item.city }} </p>
																<p> {{ data.item.state + data.item.country }} </p>
															</div>
															<div slot="item" slot-scope="data">
																<p> {{ data.item.address }} </p>
																<p> {{ data.item.address2 }} </p>
																<p> {{ data.item.postcode + ' ' + data.item.city }} </p>
																<p> {{ data.item.state + data.item.country }} </p>
															</div>
														</v-select>
													</div>
													<div
														v-else
														class="pl-9 pb-4"
													>
														<span>N/A</span>
													</div>
													<v-radio
														label="New Address"
														value="new"
														class="font-weight-black"
													></v-radio>
													<div
														v-show="selectedBillingAddressType == 'new'"
														class="pl-9"
													>
														<v-text-field
															v-model="billingAddress.addressLine1"
															label="Address Line 1"
															dense
														>
															<template v-slot:label>
																<span>Address Line 1</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-text-field
															v-model="billingAddress.addressLine2"
															label="Address Line 2"
															dense
														>
														</v-text-field>
														<v-text-field
															v-model="billingAddress.addressCity"
															label="City"
															dense
														>
															<template v-slot:label>
																<span>City</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-text-field
															v-model="billingAddress.addressPostcode"
															label="Postcode"
															dense
														>
															<template v-slot:label>
																<span>Postcode</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-text-field
															v-model="billingAddress.addressState"
															label="State"
															dense
														>
															<template v-slot:label>
																<span>State</span>
																<span class="error--text"> *</span>
															</template>
														</v-text-field>
														<v-autocomplete
															v-model="billingAddress.addressCountry"
															dense hide-details
															color="secondary"
															placeholder="Select Country"
															class="radius-10 pr-0"
															:items="optionCountryList"
														>
															<template v-slot:label>
																<span>Country</span>
																<span class="error--text"> *</span>
															</template>
														</v-autocomplete>
													</div>
													<v-radio
														label="Same As Delivery Address"
														value="same"
														class="font-weight-black"
													></v-radio>
												</v-radio-group>
											</v-container>
										</v-card>
										<v-divider></v-divider>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="12" md="4">
								<v-row
									v-if="!isCustomer"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Payment</span>
										<span class="error--text"> *</span>
										<p class="mt-3" style="width: 20em;">
											<v-select
												v-model="selectedPaymentMethod"
												filled rounded
												dense hide-details
												color="secondary"
												placeholder="Select Type"
												class="radius-10 pr-0"
												:items="optionPaymentMethods"
												item-text="name" item-value="id"
											>
											</v-select>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row
									v-if="loanPayment"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Payment Remark</span>
										<p class="mt-3" style="width: 20em;">
											<v-textarea
												v-model="paymentRemark"
												filled
												rows="2"
												row-height="3"
												label="Enter Your Payment Remark"
												no-resize
											></v-textarea>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									v-if="!isCustomer && !isShowroom"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Courier</span>
										<span class="error--text"> *</span>
										<p class="mt-3" style="width: 20em;">
											<v-select
												v-model="selectedCourierId"
												filled rounded
												dense hide-details
												color="secondary"
												placeholder="Select Type"
												class="radius-10 pr-0"
												:items="optionCourierList"
												item-text="name" item-value="id"
											>
											</v-select>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									v-else
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Courier</span>
										<span class="error--text"> *</span>
										<p class="mt-3" style="width: 20em;">
											<v-select
												v-model="selectedCourierId"
												:disabled="optionDeliveryPartnerList.length == 0"
												filled rounded
												dense hide-details
												color="secondary"
												placeholder="Select Type"
												class="radius-10 pr-0"
												:items="optionDeliveryPartnerList"
												item-text="name" item-value="id"
											>
											</v-select>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Order Quantity</span>
										<span class="error--text"> *</span>
										<p class="mt-3" style="width: 20em;">
											<v-select
												v-model="selectedOrderQuantity"
												filled rounded
												dense hide-details
												color="secondary"
												placeholder="Select Quantity"
												class="radius-10 pr-0"
												:items="optionOrderQuantity"
											>
											</v-select>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									v-if="enablePayment"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Payment Type</span>
										<span class="error--text"> *</span>
										<p class="mt-3" style="width: 20em;">
											<v-select
												v-model="selectedPaymentType"
												filled rounded
												dense hide-details
												color="secondary"
												placeholder="Select Payment Type"
												class="radius-10 pr-0"
												:items="optionPaymentTypes"
												item-text="name" item-value="id"
											>
											</v-select>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									v-if="enablePayment"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Mobile Number</span>
										<span class="error--text"> *</span>
										<p class="mt-3" style="width: 20em;">
											<v-text-field
												v-model="mobileNumber"
												placeholder="Enter Your Mobile Number"
											>
											</v-text-field>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									v-if="!isCustomer && !isShowroom"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Purchase Order Number</span>
										<p class="mt-3" style="width: 20em;">
											<v-text-field
												v-model="purchaseOrderNumber"
												placeholder="Enter Order Number"
											>
											</v-text-field>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									v-if="isShowroom"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Email</span>
										<p class="mt-3" style="width: 20em;">
											<v-text-field
												v-model="guest_email"
												placeholder="Enter Customer Email"
											>
											</v-text-field>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									v-if="isCustomer || isShowroom"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Voucher</span>
										<p class="mt-3" style="width: 20em;">
											<v-text-field
												v-model="temp_promo_code"
												placeholder="Enter Promo Code"
												clearable
												:error-messages="errors.promo_code_invalid"
												:success-messages="promo_code_success"

												@click:clear="clearPromoCode"
											>
												<template slot="append">
													<v-btn
														block
														depressed
														color="primary"
														class="text-capitalize secondary--text"
														@click="applyPromoCode"
													>
														Apply
													</v-btn>
												</template>
											</v-text-field>
										</p>
										<v-divider></v-divider>
									</v-col>			
								</v-row>
							</v-col>
							<v-col
								v-if="isCustomer || isShowroom" 
								cols="12" md="4"
							>
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<div
											class="text-left pb-4"
											style="top: 0px;right: 120px;"
										>
											<span class="font-weight-black">Price List</span>
										</div>
										<div style="width: 100%" class="mr-10">
											<PriceDetail
												:selection-counter="selectionCounter"
												:price-info="priceInfo"
												:loading="loading_price"
											></PriceDetail>
										</div>
									</v-col>
								</v-row>
							</v-col>

						</v-row>
					</v-col>
				</v-row>	
				<v-row justify="end">
					<v-col cols="12" md="3">
						<v-btn
							block
							depressed
							color="primary"
							class="text-capitalize secondary--text radius-10 mb-3"
							:disabled="buttonOff"
							@click="placeOrder"
						>
							Place Order
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</v-container>
		<v-overlay
			:value="isSubmittingOrder"
			style="z-index: 21;"
		>
			<v-progress-circular
				v-if="isSubmittingOrder"
				:rotate="-90"
				:size="96"
				:width="15"
				:value="totalUploadPercent.toFixed(2)"
				color="primary"
			>
				{{ totalUploadPercent.toFixed(2) }}
			</v-progress-circular>
			<v-progress-circular
				v-else
				indeterminate 
				size="96"
			>
				Loading...
			</v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { searchMixin } from "@/mixins/SearchMixin"
import PriceDetail from "./PriceDetail"
import { mapGetters, mapActions } from "vuex"
import userConst from '@/configs/userConst'

//Address TYPE
const DELIVERY_ADDRESS_ID = 1
const BILLING_ADDRESS_ID = 2

const DEFAULT_MAIN_COLOR_ID = 229

const LOAN_PAYMENT_ID = 2
const ONLINE_PAYMENT_ID = 3

const IPAY_PAYMENT_TYPE = 2

export default {
	components:{
		PriceDetail,
	},
	mixins: [
		resourceMixin,
		searchMixin,
		errorHandlerMixin
	],
	props:{
	},
	data(){
		return {
			selectedPaymentMethod: null,
			selectedPaymentType: null,
			selectedCourierId: null,
			selectedOrderQuantity: 1,
			optionOrderQuantity: [],
			purchaseOrderNumber: null,
			mobileNumber: null,
			paymentRemark: null,
			guest_email: null,
			selectedDeliveryAddressType: null,
			selectedBillingAddressType: null,
			selectedDeliveryAddress: {
				address: null,
				address2: null,
				city: null,
				postcode: null,
				state: null,
				country: null,
			},
			selectedBillingAddress: {
				address: null,
				address2: null,
				city: null,
				postcode: null,
				state: null,
				country: null,
			},
			deliveryAddress: {
				addressLine1: null,
				addressLine2: null,
				addressCity: null,
				addressPostcode: null,
				addressState: null,
				addressCountry: null,
			},
			billingAddress: {
				addressLine1: null,
				addressLine2: null,
				addressCity: null,
				addressPostcode: null,
				addressState: null,
				addressCountry: null,
			},
			sameAddress: false,
			optionDeliveryPartnerList: [],
			optionCourierList: [],
			optionDeliveryAddress: [],
			optionBillingAddress: [],
			optionPaymentMethods: [],
			optionPaymentTypes: [],
			optionCountryList: [],
			totalUploadPercent: 0,
			isSubmittingOrder: false,
			userConst: userConst,
			temp_promo_code: null,
			promo_code: null,
			loading_price: false,
			errors: {},
			promo_code_success: null
		}
	},
	computed:{
		...mapGetters(["orderCustomizations", "orderOptions", "partSpecs", "priceInfo", "selectedSeatModel", "selectionCounter"]),
		buttonOff(){
			if((!this.selectedPaymentMethod && !this.isCustomer) || ((!this.selectedPaymentType || !this.mobileNumber) && this.enablePayment) || !this.selectedCourierId || !this.selectedOrderQuantity || !this.selectedDeliveryAddressType || this.emptyDeliveryAddress || this.emptyBillingAddress)
				return true
			
			return false
		},
		emptyDeliveryAddress()
		{
			if((this.selectedDeliveryAddressType == 'default' && this.selectedDeliveryAddress == null) || (this.selectedDeliveryAddressType == 'new'  && (!this.deliveryAddress.addressLine1 || !this.deliveryAddress.addressCity || !this.deliveryAddress.addressPostcode || !this.deliveryAddress.addressState || !this.deliveryAddress.addressCountry)))
				return true
			return false
		},
		emptyBillingAddress()
		{

			if((this.isCustomer || this.isShowroom) && ((this.selectedBillingAddressType == 'default' && this.selectedBillingAddress == null) || (this.selectedBillingAddressType == 'new'  && (!this.billingAddress.addressLine1 || !this.billingAddress.addressCity || !this.billingAddress.addressPostcode || !this.billingAddress.addressState || !this.billingAddress.addressCountry)) || (this.selectedBillingAddressType == 'same' && this.emptyDeliveryAddress) || (!this.selectedBillingAddressType)))
				return true

			return false
		},
		isCustomer()
		{
			if(this.$authUser.isCustomer() || (this.$authUser.isBdu() && this.orderOptions.userType == this.userConst.ROLE_CONSUMER))
				return true
			
			return false
		},
		isShowroom()
		{
			if(this.$authUser.isShowroom())
				return true
			
			return false
		},
		changedAddress() {
			return {
				selectedDeliveryAddressType: this.selectedDeliveryAddressType,
				selectedDeliveryAddress: this.selectedDeliveryAddress,
				deliveryPostcode: this.deliveryAddress.addressPostcode,
				deliveryCountry: this.deliveryAddress.addressCountry,
			}
		},
		isCompleteAddress()
		{
			if((this.selectedDeliveryAddressType == 'default' && (!this.selectedDeliveryAddress.postcode || !this.selectedDeliveryAddress.country)) || (this.selectedDeliveryAddressType == 'new' && (!this.deliveryAddress.addressPostcode || !this.deliveryAddress.addressCountry)))
				return false

			return true
		},
		enablePayment()
		{
			if(this.isCustomer || (this.isShowroom && this.selectedPaymentMethod == ONLINE_PAYMENT_ID))
				return true
			
			return false
		},
		loanPayment()
		{
			if(this.selectedPaymentMethod == LOAN_PAYMENT_ID)
				return true
			
			return false
		}
	},
	watch: {
		changedAddress: {
			handler: function(val) {
				this.clearCourierList();
				if(!this.isCompleteAddress)
					return true;
				else
					this.getCourierPayload();
			},
			deep: true
		},
		selectedCourierId: {
			handler: function(val) {
				this.getOrderPrice();
			},
			deep: true
		},
		selectedPaymentMethod: {
			handler: function(val) {
				this.paymentRemark = null;
			},
			deep: true
		}
	},
	created(){
		this.initiate()
	},
	activated(){
		if(Object.keys(this.orderCustomizations).length === 0)
			this.backToCustomizationPage(true)
			
		this.scrollToTop()
		this.initiate()
	},
	methods:{
		...mapActions(["setIsCustomization", "setPriceInfo"]),
		initiate()
		{
			this.initOrderQuantity()
			this.initOrderQuantity()
			this.getCourierList()
			this.getPaymentMethods()
			this.getUserAddress()
			this.getPaymentTypes()
			this.getCountryList()	
		},
		initOrderQuantity()
		{
			for(let i = 1; i <= 100; i++)
				this.optionOrderQuantity.push(i)
		},
		placeOrder()
		{
			this.totalUploadPercent = 0
			this.isSubmittingOrder = true
			let payload = this.getOrderPayload()
			this.$api.createOrder(payload).then((res)=>{
				let { result } = res.data
				this.saveAddress()
				this.uploadSeatScreenshot(result.id)
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		saveAddress()
		{
			let user_id = this.$authUser.isBdu() ? this.orderOptions.userInfo.id : this.$auth.user().id
			if(this.selectedDeliveryAddressType == 'new' && this.deliveryAddress.addressLine1 != null)
			{
				let payload = {
					user_id: user_id,
					type_id: DELIVERY_ADDRESS_ID,
					address: this.deliveryAddress.addressLine1,
					address2: this.deliveryAddress.addressLine2,
					city: this.deliveryAddress.addressCity,
					state: this.deliveryAddress.addressState,
					postcode: this.deliveryAddress.addressPostcode,
					country: this.deliveryAddress.addressCountry,

				}
				this.$api.createUserAddress(payload).then((res)=>{
					console.log('success')
				}).catch((err)=>{
					this.errorHandler_(err)
				})
			}

			if(this.selectedBillingAddressType == 'new' && this.billingAddress.addressLine1 != null)
			{
				let payload = {
					user_id: user_id,
					type_id: BILLING_ADDRESS_ID,
					address: this.billingAddress.addressLine1,
					address2: this.billingAddress.addressLine2,
					city: this.billingAddress.addressCity,
					state: this.billingAddress.addressState,
					postcode: this.billingAddress.addressPostcode,
					country: this.billingAddress.addressCountry,

				}
				this.$api.createUserAddress(payload).then((res)=>{
					console.log('success')
				}).catch((err)=>{
					this.errorHandler_(err)
				})
			}
		},
		uploadSeatScreenshot(id)
		{
			let formData = new FormData();
			formData.set('file', this.orderCustomizations.screenshotFile);
			formData.set('order_id', id); 
			this.$api.uploadScreenshot(formData, this.onProgress).then((res)=>{
				this.totalUploadPercent += (100 * (1/(this.partSpecs.length + 1)))
				this.uploadPartSpecScreenshot(id)
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		uploadPartSpecScreenshot(id)
		{
			let promises = []
			for(let index in this.partSpecs)
			{
				if(this.partSpecs[index].imageFile)
				{
					let formData = new FormData();
					formData.set('file', this.partSpecs[index].imageFile);
					formData.set('order_id', id); 
					formData.set('material_no', this.partSpecs[index].material_no); 
					promises.push(this.$api.uploadPartSpecScreenshot(formData, this.onProgress).then((res)=>{
						this.totalUploadPercent += (100 * (1/(this.partSpecs.length + 1)))
					}).catch((err)=>{
						this.errorHandler_(err)
					}))
				}
			}
			
			Promise.all(promises).then(() => this.finalizeOrder(id))
		},
		finalizeOrder(id)
		{
			let payload = {
				id: id,
			}
			this.$api.finalizeOrder(payload).then((res)=>{
				this.sendOrderEmail(id)
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		sendOrderEmail(id)
		{
			let payload = {
				order_id: id,
			}
			this.$api.sendEmail(payload).then((res)=>{
				this.isSubmittingOrder = false
				if(this.enablePayment)
				{
					this.getPaymentGateway(id)
				}
				else
					this.$router.push("/new-order/order-confirmed")
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getPaymentGateway(id)
		{
			let payload = {
				order_id: id,
				currency: this.priceInfo.currency ? this.priceInfo.currency.iso_code : "MYR",
				amount: this.selectedPaymentType == IPAY_PAYMENT_TYPE ? 1 : this.priceInfo.grand_total,
				platform: process.env.VUE_APP_PAYMENT_PLATFORM,
				type_id: this.selectedPaymentType,
				mobile_no: this.mobileNumber
			}

			this.$api.getPaymentGateway(payload).then((res)=>{
				let url = res.data.payment_url
				window.location.href = url;
			}).catch((err)=>{
				this.errorHandler_(err)
			})


		},
		getCountryList()
		{
			let payload = {
			}

			this.$api.getCountryList(payload).then((res)=>{
				let { result } = res.data
				this.optionCountryList = result
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getOrderPayload()
		{
			let sd = this.selectedSeatModel
			let orderCustomizations = this.orderCustomizations
			let orderOptions = this.orderOptions
			let payload = {
				sd_id: sd.id,
				package_id: orderCustomizations.package_id,
				main_color_id: orderCustomizations.mainColor.id ?? DEFAULT_MAIN_COLOR_ID,
				seam_id: orderCustomizations.seam.value,
				seam_color_id: orderCustomizations.seam.seamColor ? orderCustomizations.seam.seamColor.id : 0,
				piping_color_id: orderCustomizations.seam.pipingColor ? orderCustomizations.seam.pipingColor.id : 0,
				secondary_color_id: orderCustomizations.secondaryColor ? orderCustomizations.secondaryColor.id : 0,
				logo_id: orderCustomizations.logo ? orderCustomizations.logo.id : 0,
				logo_color_id: orderOptions.logoSeamColor ? orderOptions.logoSeamColor.id : 0,
				driver_seat_id: orderOptions.driverSeat.id,
				component_id: orderOptions.component ? orderOptions.component.id : 0,
				qty: this.selectedOrderQuantity,
				dealer_remark: orderOptions.remarks,
				purchase_order: this.purchaseOrderNumber,
				payment_remark: this.paymentRemark,
				guest_email: this.guest_email,
				delivery_address: {
					delivery_address: this.selectedDeliveryAddressType == 'new' ? this.deliveryAddress.addressLine1 : this.selectedDeliveryAddress.address,
					delivery_address2: this.selectedDeliveryAddressType == 'new' ? this.deliveryAddress.addressLine2 : this.selectedDeliveryAddress.address2,
					delivery_city: this.selectedDeliveryAddressType == 'new' ? this.deliveryAddress.addressCity : this.selectedDeliveryAddress.city,
					delivery_postcode: this.selectedDeliveryAddressType == 'new' ? this.deliveryAddress.addressPostcode : this.selectedDeliveryAddress.postcode,
					delivery_state: this.selectedDeliveryAddressType == 'new' ? this.deliveryAddress.addressState : this.selectedDeliveryAddress.state,
					delivery_country: this.selectedDeliveryAddressType == 'new' ? this.deliveryAddress.addressCountry : this.selectedDeliveryAddress.country,
				},
				billing_address: {},
				courier_id: this.selectedCourierId,
				payment_method_id: this.selectedPaymentMethod ?? 0,
				promo_code: this.promo_code
			}

			if(this.$authUser.isBdu())
				payload.agent_id = orderOptions.userInfo.id

			payload.billing_address = this.getBillingAddress(payload)

			payload.part_specs = this.getPartSpecPayload()

			return payload
		},
		getPartSpecPayload()
		{
			let payload = []
			let orderCustomizations = this.orderCustomizations

			for(let part_spec of this.partSpecs)
			{
				let item = {
					material_no: part_spec.material_no,
					color_id: part_spec.color ? part_spec.color.id : orderCustomizations.mainColor.id ,
					perforation: 0,
					perforation_color_id: 0,
					design_upgrade: 0,
					design_upgrade_color_id: 0,
					special_material: 0,
				}

				if(part_spec.special_material)
				{
					item.special_material = part_spec.special_material.name
				}

				if(part_spec.perforation)
				{
					item.perforation = part_spec.perforation.name
				}
				else if(part_spec.psp)
				{
					item.perforation = part_spec.psp.psp_details.name
					item.perforation_color_id = part_spec.psp.color.id
				}

				if(part_spec.pattern_stitching)
				{
					item.design_upgrade = part_spec.pattern_stitching.pattern_stitching_details.name
					item.design_upgrade_color_id = part_spec.pattern_stitching.color.id
				}
				else if(part_spec.digital_punching)
				{
					item.design_upgrade = part_spec.digital_punching.digital_punching_details.name
				}

				payload.push(item)
			}

			return payload
		},
		getBillingAddress(payload)
		{
			let billing_address = {}
			if(this.isCustomer || this.isShowroom)
			{
				if(this.selectedBillingAddressType == 'new')
				{
					billing_address.billing_address = this.billingAddress.addressLine1;
					billing_address.billing_address2 = this.billingAddress.addressLine2;
					billing_address.billing_city = this.billingAddress.addressCity;
					billing_address.billing_postcode = this.billingAddress.addressPostcode;
					billing_address.billing_state = this.billingAddress.addressState;
					billing_address.billing_country = this.billingAddress.addressCountry;

					return billing_address
				}
				else if(this.selectedBillingAddressType == 'same')
				{
					billing_address.billing_address = payload.delivery_address.delivery_address;
					billing_address.billing_address2 = payload.delivery_address.delivery_address2;
					billing_address.billing_city = payload.delivery_address.delivery_city;
					billing_address.billing_postcode = payload.delivery_address.delivery_postcode;
					billing_address.billing_state = payload.delivery_address.delivery_state;
					billing_address.billing_country = payload.delivery_address.delivery_country;

					return billing_address
				}
				else if(this.selectedBillingAddressType == 'default')
				{
					billing_address.billing_address = this.selectedBillingAddress.address;
					billing_address.billing_address2 = this.selectedBillingAddress.address2;
					billing_address.billing_city = this.selectedBillingAddress.city;
					billing_address.billing_postcode = this.selectedBillingAddress.postcode;
					billing_address.billing_state = this.selectedBillingAddress.state;
					billing_address.billing_country = this.selectedBillingAddress.country;

					return billing_address
				}
			}

			return null;
		},
		onProgress(percent)
		{
			return false
		},
		clearCourierList()
		{
			this.optionDeliveryPartnerList = []
			if(this.selectedCourierId)
			{
				this.selectedCourierId = null
			}

		},
		getCourierPayload()
		{
			if(this.isCompleteAddress)
			{
				if(this.selectedDeliveryAddressType == "default")
					this.getDeliveryPartnerList(this.selectedDeliveryAddress.postcode, this.selectedDeliveryAddress.country)
				else if(this.selectedDeliveryAddressType == "new")
					this.getDeliveryPartnerList(this.deliveryAddress.addressPostcode, this.deliveryAddress.addressCountry)
			}
		},
		getCourierList(postcode, country)
		{
			let payload = {
				postcode: postcode,
				country: country,
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getCourierList(payload).then((res)=>{
				let { data } = res.data.result
				this.optionCourierList = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getDeliveryPartnerList(postcode, country)
		{
			let payload = {
				postcode: postcode,
				country: country,
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getDeliveryPartnerList(payload).then((res)=>{
				let { data } = res.data.result
				this.optionDeliveryPartnerList = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getPaymentMethods()
		{
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getPaymentMethod(payload).then((res)=>{
				let { data } = res.data.result
				this.optionPaymentMethods = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getPaymentTypes()
		{
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getPaymentType(payload).then((res)=>{
				let { result } = res.data
				this.optionPaymentTypes = result
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getUserAddress()
		{
			let orderOptions = this.orderOptions

			let payload = {
				user_id: this.$auth.user().id,
				itemsPerPage: 100,
				is_archived: 0,
			}

			if(this.$authUser.isBdu())
				payload.user_id = orderOptions.userInfo.id

			this.$api.getUserAddress(payload).then((res)=>{
				let { data } = res.data.result
				this.optionDeliveryAddress = data.filter((item) => 
				{
					return item.type_id == DELIVERY_ADDRESS_ID || item.type_id == null
				})
				this.optionBillingAddress = data.filter((item) => item.type_id == BILLING_ADDRESS_ID)
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		backToCustomizationPage(status)
		{
			this.setIsCustomization(status)
			this.$router.push('/new-order/customization')
		},
		async getOrderPrice()
		{
			this.loading_price = true
			this.errors = {}
			this.promo_code_success = null
			let order_payload = this.getOrderPayload()

			this.$api.getOrderPrice(order_payload).then((res)=>{
				let { result } = res.data
				this.setPriceInfo(result)
				if(this.temp_promo_code && this.promo_code)
					this.promo_code_success = "Promo code applied"
			}).catch((err)=>{
				this.errors = this.errorHandler_(err,["promo_code_invalid"])
			}).finally(()=>{
				this.loading_price = false
			})
		},
		clearPromoCode()
		{
			this.temp_promo_code = null
			this.applyPromoCode()
		},
		async applyPromoCode()
		{
			this.promo_code = this.temp_promo_code
			await this.getOrderPrice()
		},
		scrollToTop() {
			window.scrollTo(0,0);
		}
	}
}
</script>
<style scoped>
.screenshots {
  border: 2px solid #555;
}
</style>
